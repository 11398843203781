import React from 'react';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import DnsRoundedIcon from '@mui/icons-material/DnsRounded';
import PermMediaOutlinedIcon from '@mui/icons-material/PhotoSizeSelectActual';
import AddIcon from '@mui/icons-material/Add';
import LogoutIcon from '@mui/icons-material/Logout';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import {NavLink} from "react-router-dom";
import {logout} from './actions/auth';
import { useDispatch } from 'react-redux';

const categories = [
  {
    id: 'Kävelyt',
    children: [
      {
        id: 'Lisää kävely',
        icon: <AddIcon color={'secondary'} />,
        navPath: "/add"
      },
      {
        id: 'Tulokset', 
        icon: <DnsRoundedIcon color={'secondary'} />, 
        navPath: "/records"},
      { 
        id: 'Kehitys', 
        icon: <PermMediaOutlinedIcon color={'secondary'} /> ,
        navPath: "/stats"
      },
      { 
        id: 'Tietoa', 
        icon: <InfoRoundedIcon color={'secondary'} /> ,
        navPath: "/info"
      },
    ],
  },
];

const item = {
  py: '2px',
  px: 3,
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
};

const itemCategory = {
  boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
  py: 1.5,
  px: 3,
};

export default function Navigator(props) {
  const { user, ...other} = props;
  const dispatch = useDispatch();
  const logoutButton = () => {
    dispatch(logout());
  } 

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>

      <NavLink to="/" style={{textDecoration: 'none'}}>
          <ListItem sx={{ ...item, ...itemCategory, fontSize: 22, color: '#fff' }} key="index">
            Ruokahävikki
          </ListItem>

          <ListItem sx={{ ...item, ...itemCategory }} key="home">
            <ListItemIcon>
              <HomeIcon color={'secondary'}/>
            </ListItemIcon>
            <ListItemText>Etusivu</ListItemText>
          </ListItem>
        </NavLink>
        {categories.map(({ id, children }) => (
          <Box key={id} sx={{ bgcolor: '#1a1a1a'}}>
            <ListItem sx={{ py: 2, px: 3 }} key={id}>
              <ListItemText sx={{ color: '#fff' }}>{id}</ListItemText>
            </ListItem>
            {children.map(({ id: childId, icon, active, navPath }) => (
              <NavLink key={childId} to={navPath} style={{textDecoration: 'none'}}>
                <ListItem disablePadding >
                  <ListItemButton selected={active} sx={item}>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText>{childId}</ListItemText>
                  </ListItemButton>
                </ListItem>
              </NavLink>
            ))}
            <Divider sx={{ mt: 2 }} />
          </Box>
        ))}
        <Box key={'Käyttäjä'} sx={{ bgcolor: '#1a1a1a' }}>
            <ListItem sx={{ py: 2, px: 3 }} key={"Käyttäjä"}>
              <ListItemText>{'Käyttäjä: ' + user.username}</ListItemText>
            </ListItem>
            <NavLink key={'account'} to={'account'} style={{textDecoration: 'none'}}>
              <ListItem disablePadding key={'Käyttäjähallinta'}>
                <ListItemButton sx={item}>
                  <ListItemIcon><ManageAccountsIcon color={'secondary'} /></ListItemIcon>
                  <ListItemText>{'Käyttäjähallinta'}</ListItemText>
                </ListItemButton>
              </ListItem>
            </NavLink>
            <ListItem disablePadding key={'Kirjaudu ulos'} onClick={logoutButton}>
              <ListItemButton sx={item}>
                <ListItemIcon><LogoutIcon color={'secondary'} /></ListItemIcon>
                <ListItemText>{'Kirjaudu ulos'}</ListItemText>
              </ListItemButton>
            </ListItem>
            
            <Divider sx={{ mt: 2 }} />
          </Box>
      </List>
    </Drawer>
  );
}