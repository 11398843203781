import React, { useState, useEffect } from 'react';
import { Field } from 'react-final-form'
import { useFormState } from 'react-final-form';

import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormGroup from '@mui/material/FormGroup';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import questionService from '../../services/question.service';
import answerService from '../../services/answer.service';

import { useHistory } from "react-router";

import CircularProgress from '@mui/material/CircularProgress';
import Wizard from './Wizard'

const renderTextField = ({
    label,
    input,
    meta: { touched, invalid, error },
    ...custom
  }) => (
    <TextField
      label={label}
      placeholder={label}
      error={invalid}
      helperText={error}
      {...input}
      {...custom}
      touched='true'
    />
  )



const required = (value) => (value ? undefined : "Vaadittu vastaus!");
const requiredComment = (value, allValues, meta) => {
    let name = meta.name.split(".")[0];

    if((allValues[name] === undefined || allValues[name] === null || allValues[name].score === "1" || allValues[name].score === "2") && !value){
        return "Kommentti vaaditaan matalille pisteille";
    } else{
        return undefined;
    }

    //value ? undefined : "Kommentti vaaditaan matalille pisteille";
};

const Error = ({ name }) => (
    <Field
        name={name}
        subscription={{ touched: true, error: true }}
        render={({ meta: { touched, error } }) =>
        touched && error ? <Typography sx={{color: 'red', fontWeight: 'bold'}}>{error}</Typography> : null
        }
    />
)

const radioButton = ({ input, ...rest }) => (
    <FormControl>
        <RadioGroup row {...input} {...rest}>
            <FormControlLabel labelPlacement="top" value="1" control={<Radio />} label="1" />
            <FormControlLabel labelPlacement="top" value="2" control={<Radio />} label="2" />
            <FormControlLabel labelPlacement="top" value="3" control={<Radio />} label="3" />
            <FormControlLabel labelPlacement="top" value="4" control={<Radio />} label="4" />
        </RadioGroup>
    </FormControl>
)

function Question(props){
    const {question} = props;

    const { values } = useFormState();
    let value = null;
    if(values["q" + question.id]){
        value = values["q" + question.id].score;
    }

    const [questionId, setQuestionId] = useState(1);

    useEffect(() => {
        setQuestionId(question.id);
      }, [question.id, setQuestionId]);

    return(
        <div>
            <Divider variant="middle" />
            <FormGroup sx={{marginTop: 2, marginBottom: 2}}>
                <Typography variant="h6" component="h2">{question.fin}</Typography>
                <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                    <Field validate={required} validateFields={["q" + questionId + ".comment", ]} sx={{display: 'flex', justifyContent: 'start', marginTop: 2, marginBottom: 2, marginRight: 4}} name={"q" + questionId + ".score"} component={radioButton} type="radio" value={value} initialValue={value}/>
                    <Error name={"q" + questionId + ".score"}/>
                </div>
                    <Field
                        validate={requiredComment}
                        name={"q" + questionId + ".comment"}
                        component={renderTextField}
                        label="Kommentti"
                        validateFields={[]}
                    />
                <Field type="text" name={"q" + questionId + ".questionId"} defaultValue={questionId} initialValue={questionId} value={questionId} render={() => null}></Field>
            </FormGroup>
        </div>
    );
}

function Category(props){
    const {category} = props;

    return(
        <div>
            <Typography variant="h4" component="h2" sx={{marginBottom: 2}}>{category.fin}</Typography>
            {category.questions.map((question) => (
                        <Question key={question.id} question={question} />
                    ))}
        </div>  
    )
}

var history;

const onSubmit = async (submitData) => {
    
    answerService.postAnswers(Object.values(submitData))
    .then(() => {
        history.push("/records");
    });
}

export default function Add(props){
    const [loadingData, setLoadingData] = useState(true);
    const [data, setData] = useState([]);

    history = useHistory();

    const {setTitle} = props;
    
    useEffect(() => {
        setTitle("Lisää kävely");
    }, [setTitle]);

    useEffect(() => {
        questionService.getQuestions().then(result => {
        setData(result.data);
        setLoadingData(false);
        });
    }, []);
    
    
    return(
        <div>
            {loadingData ? (
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">
                        Ladataan tietoja...
                    </Typography>
                    <CircularProgress align="center"/>
                </div>
            ) : (
            <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden', marginBottom: 5, padding: 5 }}>
                <Wizard 
                    onSubmit = {onSubmit}
                    >
                    {data.map((category) => (
                        <Wizard.Page>
                            <Category key={category.id} category={category} />
                        </Wizard.Page>
                    ))} 
                </Wizard>
            </Paper>
        )}
        </div>  
    )
} 