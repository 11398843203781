import React, { Component } from "react";
import { Redirect } from 'react-router-dom';

import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { connect } from "react-redux";
import { login } from "../../actions/auth";
import { history } from '../../helpers/history';

class Login extends Component {
  constructor(props) {
    super(props);
    this.register = this.register.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.state = {
      username: "",
      password: "",
      loading: false,
    };
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value,
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  handleLogin(e) {
    e.preventDefault();
    
    this.setState({
      loading: true,
    });

    this.form.validateAll();

    const { dispatch } = this.props;

    if (this.checkBtn.context._errors.length === 0) {
      dispatch(login(this.state.username, this.state.password))
        .then(() => {
          history.push("/");
          window.location.reload(false);
        })
        .catch(() => {
          this.setState({
            loading: false
          });
        });
    } else {
      this.setState({
        loading: false,
      });
    }
  }
  
  register(e) {
    e.preventDefault();
    history.push("/register");
    window.location.reload(false);
  }

  reset(e) {
    e.preventDefault();
    history.push("/reset");
    window.location.reload(false);
  }

  render() {
    const { isLoggedIn, message } = this.props;

    if (isLoggedIn) {
      return <Redirect to="/" />;
    }

    return (
      <div style={{display:'flex', justifyContent: 'center', height: '100vh'}}>
        <div style={{display:'flex', justifyContent: 'center', flexDirection: 'column'}}>
          <Card variant="outlined" sx={{ flexWrap: 'wrap'}}>
            <CardContent>
              <Form
                onSubmit={this.handleLogin}
                ref={(c) => {
                  this.form = c;
                }}
              >
                <div className="form-group">
                  <TextField 
                    id="standard-basic" 
                    label="Käyttäjätunnus" 
                    variant="standard"
                    autoComplete="username"
                    value={this.state.username}
                    onChange={this.onChangeUsername}
                    sx={{margin: 1}} />
                </div>

                <div className="form-group">
                  <TextField
                    id="outlined-password-input"
                    label="Salasana"
                    type="password"
                    variant="standard"
                    autoComplete="current-password"
                    value={this.state.password}
                    onChange={this.onChangePassword}
                    sx={{margin: 1}}
                  />
                </div>

                <div className="form-group" style={{display:'flex', justifyContent: 'center', flexDirection: 'column'}}>
                  <Button variant="contained"
                    disabled={this.state.loading}
                    type="submit"
                    sx={{margin: 1}}>
                      Kirjaudu
                  </Button>
                </div>

                {message && (
                  <div className="form-group">
                    <div className="alert alert-danger" role="alert">
                      {message}
                    </div>
                  </div>
                )}
                <CheckButton
                  style={{ display: "none" }}
                  ref={(c) => {
                    this.checkBtn = c;
                  }}
                />
              </Form>
              <div style={{display:'flex', justifyContent: 'center', flexDirection: 'column'}}>
                  <Button variant="text"
                    disabled={this.state.loading}
                    sx={{marginTop: 1, mx:1}}
                    onClick={this.register}>
                      Rekisteröidy
                  </Button>
              </div>
              <div style={{display:'flex', justifyContent: 'center', flexDirection: 'column'}}>
                  <Button variant="text"
                    disabled={this.state.loading}
                    sx={{marginTop: 1, mx:1}}
                    onClick={this.reset}>
                      Unohtuiko salasana?
                  </Button>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { isLoggedIn } = state.auth;
  const { message } = state.message;
  return {
    isLoggedIn,
    message
  };
}

export default connect(mapStateToProps)(Login);