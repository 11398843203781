
import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import tokenService from '../../services/token.service';
import { Form, Field } from 'react-final-form'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import authService from '../../services/auth.service';

const renderTextField = ({
    label,
    input,
    meta: { touched, invalid, error },
    ...custom
  }) => (
    <TextField
      label={label}
      placeholder={label}
      error={invalid}
      helperText={error}
      {...input}
      {...custom}
      touched='true'
      type="password"
    />
  )



export default function Account(props){
    const {setTitle} = props;
    
    useEffect(() => {
        setTitle("Profiili");
    }, [setTitle]);

    const [valid, setValid] = useState(false);
    const [message, setMessage] = useState({text: '', color: '#FF0000'});

    const changePassword = async (submitData, form) => {
        setMessage({text: 'Vaihdetaan...', color: '#000000'});
        authService.changePassword(submitData)
        .then(() => {
            setMessage({text: 'Salasana vaihdettu!', color: '#03C04A'});
            form.reset();
        }).catch(err =>{
            console.log(err.message)
            setMessage({text: 'Salasanan vaihto epäonnistui!', color: '#FF0000'});
        });
    } 

    const validate = (value, allValues, meta) => {
        if(allValues['pw1'] !== allValues['pw2']){
            setValid(false);
            return "Salasanat eivät vastaa toisiaan";
        }

        if(allValues['pw1'] === undefined || allValues['pw1'] === null ){
            setValid(false);
            return undefined;
        }

        if(value.length < 4 || value.length > 20){
            setValid(false);
            return "Salasanan pituus: 4-20";
        }

        setValid(true);
        return undefined;
    }

    return(
        <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden', marginBottom: 5, padding: 5 }}>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
                    <b>Käyttäjätunnus</b><br/>{tokenService.getUsername()}
                </Typography>
                <Typography sx={{ my: 5, mx: 15 }} color="text.secondary" align="left">
                    <b>Sähköposti</b><br/>{tokenService.getEmail()}
                </Typography>
            </div>
            <Typography sx={{ marginTop: 5, mx: 2 }} color="text.secondary" align="left">
                <b>Vaihda salasana</b>
            </Typography>
            <Form
                onSubmit={changePassword}
                render={({handleSubmit, submitting}) =>(
                    <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column'}}>
                        <Field
                            name={"pw1"}
                            validate={validate}
                            component={renderTextField}
                            label="Uusi salasana"
                            sx={{ my: 2, mx: 2, width: 225 }}
                        />
                        <Field
                            name={"pw2"}
                            validate={validate}
                            component={renderTextField}
                            label="Toista uusi salasana"
                            sx={{ my: 2, mx: 2, width: 225 }}
                        />
                        <Button type="submit" variant="contained" sx={{ my: 1, mx: 2, width: 225 }} disabled={!valid | submitting}>
                          Vaihda
                        </Button>
                    </form>
                )}
            />
            <Typography sx={{ marginTop: 5, mx: 2 }} color={message.color} align="left">
                <b>{message.text}</b>
            </Typography>
        </Paper>
        
    )
}