import React, {useEffect} from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';

export default function Info(props){
    
    const {setTitle} = props;

    useEffect(() => {
        setTitle("Tietoa ohjelmasta");
    }, [setTitle]);

    return (
        <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden', padding: 5 }}>
            <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
                Hävikin hallinta on osa keittiötoiminnan laatua. Seuranta ja tarkastelu ovat tärkeässä roolissa toiminnan kehittämisessä. Tämän sovelluksen tarkoituksena on tarjota ruokapalveluyrityksille tiedonkeruujärjestelmä, jonka aikana käyttäjä <b>arvostelee toiminnan yhtätoista eri osa-aluetta</b>, kuten laitteiston käyttöä, toimitilojen siisteyttä, henkilöstön osaamista sekä yleistä toimintaa <b>neliportaisella asteikolla.</b>
            </Typography>
            <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
                <b>4 Kiitettävä.</b> Toimipaikan tilat ja laitteet ovat siistit ja toimivat asiallisesti. Laitteiden huolto, ja tarkastus on jatkuvaa. Henkilökunnan osaaminen sekä toimintaprosessit toimivat vaatimusten mukaisesti. Toimipaikan ilmapiiri kannustaa toimimaan yhdessä parempien valintojen ja huolellisuuden eteen.
            </Typography>
            <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
                <b>3 Hyvä.</b> Toiminnassa on pieniä epäkohtia, kehitettävää ja korjattavaa. Toiminnassa kuitenkin tiedostetaan hävikki, ja pyritään parantamaan toiminnan laatua.
            </Typography>
            <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
                <b>2 Tyydyttävä.</b> Toiminnassa on epäkohtia, jotka selkeästi heikentävät toiminnan tehokkuutta sekä vaikuttavat hävikin kasvuun. Epäkohtien korjaaminen sekä toimintamallien parantaminen on ainoa tapa vaikuttaa hävikin vähentämiseen. Vaatii toimenpiteitä.
            </Typography>
            <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
            <b>1 Huono.</b> Toiminnassa epäkohtia, jotka vaikuttavat yrityksen kannattavaan ja vastuulliseen toimintaan. Epäkohdat ja toiminnan virheet on korjattava ja vaatii pikaisia toimenpiteitä.
            </Typography>
            <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
                Hävikkikeittiökävely-sovelluksen pohjana on LEAN-ajatteluun perustuva 5S-malli. Malli on askel kohti parempaa tuottavuutta, järjestelmällisyyttä, työturvallisuutta, laatua, ajallaan toimittamista, voittoa ja työntekijöiden sitoutumista.
            </Typography>
            <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
                Hävikkikeittiökävely-sovellus tallentaa tiedot järjestelmään tehdyn päivän mukaan, laskee toiminnalle keskiarvon, tuottaa kuvaajaa sekä ”pakottaa” toimijan tekemään tarvittaessa korjaustoimenpide ehdotuksen pisteytyksen ollessa matala.
            </Typography>
            <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
               Kerättyjä tietoja voidaan tarkkailla kävelykohtaisesti. Sivustolle jää muistiin aiemmat kävelyt. Käyttäjä voi seurata keskiarvon ja toiminnan kehitystä listauksen sekä kuvaajan kautta. 
            </Typography>
            <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
              Tämän sovellus on tuotettu osana Ruokahävikki kuriin Kaakkois-Suomen maaseudun ruokapalveluyrityksissä-hanketta. Voit lukea hankkeesta lisää täältä <Link>www.xamk.fi/ruokahavikkikuriin</Link>
            </Typography>
        </Paper>
    )
}