import React, {Component} from 'react';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import { connect } from "react-redux";
import Paperbase from './Paperbase';
import authService from './services/auth.service';
import AuthVerify from './common/auth-verify';
import Reset from './components/auth/Reset';

class App extends Component{

  constructor(props) {
    super(props);

    this.logOut = this.logOut.bind(this);

    this.state = {
      currentUser: undefined
    };
  }

  logOut() {
    this.setState({
      currentUser: undefined
    });
    authService.logout();
  }

  componentDidMount() {
    const user = authService.getCurrentUser();
    if (user) {
      this.setState({
        currentUser: user
      });
    }
  }

  render(){
    const {currentUser} = this.state;

    return(
      <Router>
        <Switch>
          <Route path= "/login">
            <Login/>
          </Route>
          <Route path= "/register">
            <Register/>
          </Route>
          <Route path= "/reset">
            <Reset/>
          </Route>
          <Route path= "/">
            {currentUser ? <Paperbase user={currentUser} /> : <Login/>}
          </Route>
        </Switch>
        <AuthVerify logOut={this.logOut}/>
      </Router>
    )
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(App);