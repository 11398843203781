import React, {useEffect} from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import DnsRoundedIcon from '@mui/icons-material/DnsRounded';
import PermMediaOutlinedIcon from '@mui/icons-material/PhotoSizeSelectActual';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import {NavLink} from "react-router-dom";

export default function Home(props) {

  const {setTitle} = props;

  useEffect(() => {
    setTitle("Hävikki");
}, [setTitle]);

  return (
    <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden', padding: 5 }}>
      <Grid container spacing={6} alignItems="center">
      <Grid item xs={12} sm={6}>
          <NavLink to={"/add"} style={{textDecoration: 'none'}}>
            <Paper sx={{ backgroundColor: '#ffbc1c', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: 5, paddingBottom: 5}}>
              <AddIcon sx={{ fontSize: 128, color: '#000000' }}/>
              <Typography color='#000000'>Lisää kävely</Typography>
            </Paper>
          </NavLink>
        </Grid>
        <Grid item xs={12} sm={6}>
          <NavLink to={"/records"} style={{textDecoration: 'none'}}>
            <Paper sx={{ backgroundColor: '#107baa', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: 5, paddingBottom: 5}}>
              <DnsRoundedIcon sx={{ fontSize: 128, color: '#ffffff' }}/>
              <Typography color='#ffffff'>Tulokset</Typography>
            </Paper>
          </NavLink>
        </Grid>
        <Grid item xs={12} sm={6}>
          <NavLink to={"/stats"} style={{textDecoration: 'none'}}>
            <Paper sx={{ backgroundColor: '#ebebed', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: 5, paddingBottom: 5}}>
              <PermMediaOutlinedIcon sx={{ fontSize: 128, color: '#000000' }}/>
              <Typography color='#000000'>Kehitys</Typography>
            </Paper>
          </NavLink>
        </Grid>
        <Grid item xs={12} sm={6}>
          <NavLink to={"/info"} style={{textDecoration: 'none'}}>
            <Paper sx={{ backgroundColor: '#000000', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: 5, paddingBottom: 5}}>
              <InfoRoundedIcon sx={{ fontSize: 128, color: '#ffffff' }}/>
              <Typography color='#ffffff'>Tietoa sovelluksesta</Typography>
            </Paper>
          </NavLink>
        </Grid>
      </Grid>
    </Paper>
  );
}