var environment = process.env.NODE_ENV
var isDevelopment = environment === 'development'

const conf = {};

if(isDevelopment){
    conf.API_URL = "http://localhost:8080/api";
}else{

    conf.API_URL = "https://ruokahavikki.xamk.fi/api";
}

module.exports = conf;