import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Navigator from './Navigator';
import Home from './components/home/Home';
import Header from './Header';
import {Switch, Route} from "react-router-dom";
import Stats from './components/stats/Stats';
import Records from './components/records/Records';
import Add from './components/add/Add';
import Info from './components/info/Info';

import xamk from './images/xamk_logo_laaja2_mv_rgb.png';
import maaseuturahasto from './images/maaseuturahasto_b.png';
import maaseutu2020 from './images/maaseutu2020_oranssi.png';
import taitaja from './images/taitaja-logo.svg';
import ely from './images/elyfi.png';
import Account from './components/account/Account';

let theme = createTheme({
  palette: {
    /*primary: {
      light: '#63ccff',
      main: '#009be5',
      dark: '#006db3',
    },*/
    primary: {
      light: '#57aadc',
      main: '#107baa',
      dark: '#004f7b',
    },
    secondary: {
      light: '#ffee58',
      main: '#ffbc1c',
      dark: '#c78c00'
    }
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#000000',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        contained: {
          boxShadow: 'none',
          '&:active': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          margin: '0 16px',
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up('md')]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgb(255,255,255,0.15)',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#4fc3f7',
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: '#ffffff',
          fontSize: 14,
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: '#ffffff',
          minWidth: 'auto',
          marginRight: theme.spacing(2),
          '& svg': {
            fontSize: 20,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
  },
};

const drawerWidth = 256;

export default function Paperbase(props) {
  const { user } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [title, setTitle] = React.useState("Hävikki");
  const isSmUp = useMediaQuery(theme.breakpoints.up('md'));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', minHeight: '100vh' }}>
        <CssBaseline />
        <Box
          component="nav"
          sx={{ width: { md: drawerWidth }, flexShrink: { sm: 0 } }}
        >
          {isSmUp ? null : (
            <Navigator
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              user={user}
            />
          )}

          <Navigator
            PaperProps={{ style: { width: drawerWidth } }}
            sx={{ display: { md: 'block', xs: 'none' } }}
            user={user}
          />
        </Box>
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <Header onDrawerToggle={handleDrawerToggle} title={title} />
          <Box component="main" sx={{ flex: 1, py: 6, px: 4, bgcolor: '#eaeff1' }}>
            <Switch>
                <Route path="/add">
                    <Add setTitle = {setTitle}/>
                </Route>
                <Route path="/stats">
                    <Stats setTitle = {setTitle}/>
                </Route>
                <Route path="/records">
                    <Records setTitle = {setTitle}/>
                </Route>
                <Route path="/info">
                    <Info setTitle = {setTitle}/>
                </Route>
                <Route path="/account">
                    <Account setTitle = {setTitle}/>
                </Route>
                <Route path="/">
                    <Home setTitle = {setTitle}/>
                </Route>
            </Switch>
          </Box>
          <Box component="footer" sx={{ p: 2, bgcolor: '#eaeff1', display:'flex', justifyContent:'center', flexWrap: 'wrap', alignItems:'center' }}>
            <img src={maaseuturahasto} alt='Maaseuturahasto' style={{marginTop: 12, height: 110, margin: 15}}/>
            <img src={ely} alt='ELY' style={{marginTop: 12, height: 80, margin: 15}}/>
            <img src={maaseutu2020} alt='Maaseutu 2020' style={{marginTop: 12, height: 90, margin: 15}}/>
            <img src={taitaja} alt='Taitajan tie' style={{marginTop: 12, height: 55, margin: 15}}/>
            <img src={xamk} alt='xamk' style={{marginTop: 12, height: 80, margin: 15}}/>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}