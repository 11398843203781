import React, { Component } from "react";
import Form from "react-validation/build/form";
//import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { connect } from "react-redux";
import { register } from "../../actions/auth";
import { history } from '../../helpers/history';
import { login } from "../../actions/auth";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        Kenttä vaadittu!
      </div>
    );
  }
};

const email = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        Ei sähköposti!
      </div>
    );
  }
};

const vusername = (value) => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        Käyttäjätunnuksen pituus tulee olla 3-20.
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        Salasanan pituus tulee olla 6-40.
      </div>
    );
  }
};

class Register extends Component {
  constructor(props) {
    super(props);
    this.login = this.login.bind(this);
    this.handleRegister = this.handleRegister.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.state = {
      username: "",
      email: "",
      password: "",
      successful: false,
    };
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value,
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  handleRegister(e) {
    e.preventDefault();

    this.setState({
      successful: false,
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      this.props
        .dispatch(
          register(this.state.username, this.state.email, this.state.password)
        )
        .then(() => {
          this.setState({
            successful: true,
          });
          this.props.dispatch(login(this.state.username, this.state.password))
            .then(() => {
              history.push("/");
              window.location.reload(false);
            })
            .catch(() => {
              this.setState({
                loading: false
              });
            });
        })
        .catch(() => {
          this.setState({
            successful: false,
          });
        });
    }
  }

  login(e) {
    e.preventDefault();
    history.push("/login");
    window.location.reload(false);
  }

  render() {
    const { message } = this.props;

    return (
      <div style={{display:'flex', justifyContent: 'center', height: '100vh'}}>
        <div style={{display:'flex', justifyContent: 'center', flexDirection: 'column'}}>
          <Card variant="outlined" sx={{ flexWrap: 'wrap'}}>
            <CardContent>
              <Form
                onSubmit={this.handleRegister}
                ref={(c) => {
                  this.form = c;
                }}
              >
                {!this.state.successful && (
                  <div>
                    <div className="form-group">
                      <TextField
                        label="Käyttäjätunnus" 
                        type="text"
                        className="form-control"
                        name="username"
                        autoComplete="username"
                        value={this.state.username}
                        onChange={this.onChangeUsername}
                        validations={[required, vusername]}
                        sx={{margin: 1}}
                      />
                    </div>

                    <div className="form-group">
                      <TextField
                        label="Sähköposti" 
                        type="text"
                        className="form-control"
                        name="email"
                        autoComplete="email"
                        value={this.state.email}
                        onChange={this.onChangeEmail}
                        validations={[required, email]}
                        sx={{margin: 1}}
                      />
                    </div>

                    <div className="form-group">
                      <TextField
                        label="Salasana"
                        type="password"
                        className="form-control"
                        name="password"
                        autoComplete="current-password"
                        value={this.state.password}
                        onChange={this.onChangePassword}
                        validations={[required, vpassword]}
                        sx={{margin: 1}}
                      />
                    </div>

                    <div className="form-group" style={{display:'flex', justifyContent: 'center', flexDirection: 'column'}}>
                      <Button variant="contained"
                        disabled={this.state.loading}
                        type="submit"
                        sx={{margin: 1}}>
                          Rekisteröidy
                      </Button>
                    </div>
                  </div>
                )}

                {message && (
                  <div className="form-group">
                    <div className={ this.state.successful ? "alert alert-success" : "alert alert-danger" } role="alert">
                      {message}
                    </div>
                  </div>
                )}
                <CheckButton
                  style={{ display: "none" }}
                  ref={(c) => {
                    this.checkBtn = c;
                  }}
                />
              </Form>
              <div style={{display:'flex', justifyContent: 'center', flexDirection: 'column'}}>
                  <Button variant="text"
                    disabled={this.state.loading}
                    sx={{marginTop: 1, mx:1}}
                    onClick={this.login}>
                      Kirjaudu
                  </Button>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message,
  };
}

export default connect(mapStateToProps)(Register);