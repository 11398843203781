import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import answerService from '../../services/answer.service';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';

function getAverage(arr) {
  let reducer = (total, currentValue) => total + currentValue;
  let sum = arr.reduce(reducer)
  return Number((sum / arr.length).toFixed(1));
}

function AnswerRow(props){
  const answerrow = props.answerrow;

  return (
    <React.Fragment>
      <TableRow key={answerrow.id}>
        <TableCell scope="row">{answerrow.question.fin}</TableCell>
        <TableCell >{answerrow.comment}</TableCell>
        <TableCell align="right">{answerrow.score}</TableCell>
      </TableRow>
    </React.Fragment>
  )
}

function Category(props){
  const [open, setOpen] = useState(false);
  const answerrows = props.answerrows;
  const category = props.category;


  return (
    <React.Fragment>
      <TableRow key={props.category.id} onClick={() => setOpen(!open)}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell scope="row">{category.fin}</TableCell>
        <TableCell align="right">{getAverage(answerrows.map((answerrow) => answerrow.score))}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, border: 2, borderColor: '#e8eaf5', padding: 2 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow key={category.id}>
                    <TableCell scope="header"><b>Kysymys</b></TableCell>
                    <TableCell scope="header"><b>Kommentti</b></TableCell>
                    <TableCell scope="header" align="right"><b>Pisteet</b></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {answerrows.map((answerrow) => (
                  <AnswerRow key={answerrow.id} answerrow={answerrow} />
                ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

function Answer(props) {
  const answer  = props.answer;
  const categories  = props.categories;
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} onClick={() => setOpen(!open)}>
        <TableCell sx={{width:'80px'}}>
          <IconButton
            aria-label="expand row"
            size="small"
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{moment(answer.timestamp).format('DD.MM.YYYY')}</TableCell>
        <TableCell align="right" sx={{ paddingRight: 5 }}>{getAverage(answer.answerrows.map((answerrow) => answerrow.score))}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, border: 2, borderColor: '#e8eaf5', padding: 2 }}>
              <Typography variant="h6" gutterBottom component="div">
                <b>Kävely: {answer.id}</b>
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell><b>Kategoria</b></TableCell>
                    <TableCell align="right"><b>Keskiarvo</b></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {categories.map((category) => (
                  <Category key={category.id} answerrows={answer.answerrows.filter((answerrow) => {return answerrow.question.categoryId === category.id})} category={category} />
                ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}



export default function Records(props) {

  const {setTitle} = props;

  useEffect(() => {
    setTitle("Tulokset");
  }, [setTitle]);

  const [loadingData, setLoadingData] = useState(true);

  const [data, setData] = useState([]);

  useEffect(() => {
    answerService.getAnswers().then(result => {
      setData(result.data);
      setLoadingData(false);
    });
  }, []);

  return (
    <TableContainer component={Paper} sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }}>
      {loadingData ? (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">
                Ladataan tietoja...
            </Typography>
            <CircularProgress align="center"/>
        </div>
      ) : (
        <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell sx={{width:'80px'}}/>
            <TableCell><b>Päivämäärä</b></TableCell>
            <TableCell align="right" sx={{ paddingRight: 5 }}><b>Keskiarvo</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.answers.map((answer) => (
            <Answer key={answer.id} answer={answer} categories={data.categories} />
          ))}
        </TableBody>
      </Table>
      )}
    </TableContainer>
  );
}