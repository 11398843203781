import api from './api';
import TokenService from "./token.service";
import {history} from "../helpers/history"
class AuthService {
  login(username, password) {
    return api
      .post("/auth/signin", { username, password })
      .then((response) => {
        if (response.data.accessToken) {
          TokenService.setUser(response.data);
        }

        return response.data;
      });
  }

  logout() {
    TokenService.removeUser();
    history.push("/");
    window.location.reload(false);
  }

  loggedin(){
    return api.get("/loggedin");
  }
   
  getCurrentUser(){
    return JSON.parse(localStorage.getItem("user"));
  }

  register(username, email, password) {
    return api.post("/auth/signup", {
      username,
      email,
      password,
    });
  }

  changePassword(password){
    return api.post("/auth/changepass", {
      password
    })
  }

  resetPassword(username){
    return api.post("/auth/reset", {
      username
    })
  }
}

export default new AuthService();