import React, { useState } from 'react';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Form, Field } from 'react-final-form'
import authService from '../../services/auth.service';
import { history } from '../../helpers/history';

const renderTextField = ({
    label,
    input,
    meta: { touched, invalid, error },
    ...custom
  }) => (
    <TextField
      label={label}
      placeholder={label}
      error={invalid}
      helperText={error}
      {...input}
      {...custom}
      touched='true'
      type="password"
    />
  )

export default function Reset(props) {
  
    const [message, setMessage] = useState({text: '', color: '#FF0000'});
    const [valid, setValid] = useState(false);

    const resetPassword = async (submitData, form) => {
        console.log('reset');
        console.log(submitData);
        setMessage({text: 'Nollataan...', color: '#000000'});
        authService.resetPassword(submitData)
        .then(() => {
            setMessage({text: 'Uusi salasana on toimitettu käyttäjätunnuksen sähköpostiin.', color: '#03C04A'});
            form.reset();
        })
        .catch(err => {
            console.log(err.message);
            if(err.response){
                console.log(err.response.data.message);
                setMessage({text: err.response.data.message, color: '#FF0000'});
            }else{
                console.log("Yhteysvirhe");
                setMessage({text: 'Yhteysvirhe', color: '#FF0000'});
            }
        });
    }

    const required = (value) => {
        if (!value) {
            setValid(false);
            return "Kenttä vaadittu!";
        }else{
            setValid(true);
            return "";
        }
    };

    const login = (e) => {
        e.preventDefault();
        history.push("/login");
        window.location.reload(false);
    }

    return (
        <div style={{display:'flex', justifyContent: 'center', height: '100vh'}}>
            <div style={{display:'flex', justifyContent: 'center', flexDirection: 'column'}}>
                <Card variant="outlined" sx={{ flexWrap: 'wrap'}}>
                <CardContent>
                    <Form 
                        onSubmit={resetPassword}
                        render={({handleSubmit, submitting}) =>(
                        <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column'}}>
                            <Field
                                name={"username"}
                                validate={required}
                                component={renderTextField}
                                label="Käyttäjätunnus"
                                autoComplete="username"
                                sx={{ my: 2, mx: 2, width: 225 }}
                            />

                            <Button variant="contained"
                                disabled={submitting || !valid}
                                type="submit"
                                sx={{margin: 1}}>
                                    Nollaa salasana
                            </Button>
                        </form>
                        )}
                    />
                    { message.text.length > 0 ?
                        <Typography sx={{ my: 2, mx: 2 }} color={message.color} align="center">
                            <b>{message.text}</b>
                        </Typography>
                        :
                        null
                    }
                    <div style={{display:'flex', justifyContent: 'center', flexDirection: 'column'}}>
                        <Button variant="text"
                            sx={{marginTop: 1, mx:1}}
                            onClick={login}>
                            Kirjaudu
                        </Button>
                    </div>
                </CardContent>
                </Card>
            </div>
        </div>
    )
}
