import api from './api';
class AnswerService {
  getAnswers() {
    return api.get('/answer/all');
  }

  postAnswers(answers){
    return api.post('/answer/add', answers);
  }
}

export default new AnswerService();