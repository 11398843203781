import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import { ResponsiveLine } from '@nivo/line'
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import answerService from '../../services/answer.service';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';


function getAverage(arr) {
    let reducer = (total, currentValue) => total + currentValue;
    let sum = arr.reduce(reducer)
    return Number((sum / arr.length).toFixed(1));
  }

function Stats(props){

    const {setTitle} = props;

    useEffect(() => {
        setTitle("Kehitys");
    }, [setTitle]);

    const [startDate, setStartDate] = useState(moment().subtract(1, 'months'));
    const [endDate, setEndDate] = useState(moment());

    const handleChangeStart = (newValue) => {
        setStartDate(newValue);
    };

    const handleChangeEnd = (newValue) => {
        setEndDate(newValue);
    };

    const [loadingData, setLoadingData] = useState(true);

    const [data, setData] = useState([]);
    const [chartData, setChartData] = useState([]);


    useEffect(() => {
        answerService.getAnswers().then(result => {
          setData(result.data.answers);
          setLoadingData(false);
        });
      }, []);

    useEffect(() => {
        if(data.length > 0){
            var tempArray = [];
            var answers = data.filter((answer) => {return moment(answer.timestamp).isBetween(startDate, endDate, 'day', '[]')});
            answers = answers.sort((a,b) => moment(a.timestamp).format('YYYYMMDD') - moment(b.timestamp).format('YYYYMMDD'));
            answers.forEach(answer => {
                var avg = getAverage(answer.answerrows.map((answerrow) => answerrow.score));
                tempArray.push({
                    x: moment(answer.timestamp).format('DD.MM'),
                    y: avg
                })
            });
            setChartData([
                {
                    id: 'Keskiarvo',
                    data: tempArray
                }
            ]);
        }
    }, [data, startDate, endDate]);

    return(
        <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden'}}>
            <LocalizationProvider dateAdapter={DateAdapter}>
                <div style={{ display: 'flex', flexDirection: 'row', margin: 20, justifyContent: 'center'}}>
                    <MobileDatePicker
                        label="Aloitus pvm"
                        inputFormat="DD.MM.yyyy"
                        value={startDate}
                        onChange={handleChangeStart}
                        renderInput={(params) => <TextField {...params}
                        style={{marginRight: 15, width: 120}} />}
                    />
                    <MobileDatePicker
                        label="Loppu pvm"
                        inputFormat="DD.MM.yyyy"
                        value={endDate}
                        onChange={handleChangeEnd}
                        renderInput={(params) => <TextField {...params}
                        style={{width: 120}} />}
                    />   
                </div>   
            </LocalizationProvider>

            {loadingData ? (
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">
                        Ladataan tietoja...
                    </Typography>
                    <CircularProgress align="center"/>
                </div>
            ) : (
                <div style={{height: 420}}>
                    <ResponsiveLine
                        data={chartData}
                        margin={{ top: 0, right: 60, bottom: 70, left: 60 }}
                        xScale={{ type: 'point' }}
                        yScale={{ type: 'linear', min: '0', max: '4.5', stacked: false, reverse: false }}
                        yFormat=">-.0d"
                        colors='#187cac'
                        gridYValues={[1, 2, 3, 4]}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            orient: 'bottom',
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'Päivämäärä',
                            legendOffset: 36,
                            legendPosition: 'middle'
                        }}
                        axisLeft={{
                            orient: 'left',
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'Pisteet',
                            legendOffset: -40,
                            legendPosition: 'middle',
                            tickValues: [0,1,2,3,4]
                        }}
                        pointSize={10}
                        pointColor={{ theme: 'background' }}
                        pointBorderWidth={2}
                        pointBorderColor={{ from: 'serieColor' }}
                        pointLabelYOffset={-12}
                        useMesh={true}
                        
                    />
                </div>
            )}
        </Paper>         
    )
}

export default Stats;